import { GetterTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ReportsStoreType } from '@/types/store/reports-store.type';

export const getters: GetterTree<ReportsStoreType, StoreType> = {
  getProductBrandReport(state) {
    return state.productBrandReport;
  },

  getProductDetails(state) {
    return state.productDetails;
  },

  getTacosAcosReport(state) {
    return state.tacosAcosReport;
  },

  getTacosReport(state) {
    return state.tacosReport;
  },

  getProductWeeklyReport(state) {
    return state.productWeeklyReport;
  },

  getTacosDynamicReport(state) {
    return state.tacosDynamicReport;
  },

  getRankingTopKwsReport(state) {
    return state.rankingTopKwsReport;
  },

  getRankingOrganicSponsoredReport(state) {
    return state.rankingOrganicSponsoredReport;
  },

  getSearchVolumeReport(state) {
    return state.searchVolumeReport;
  },

  getProductViewReport(state) {
    return state.productViewReport;
  },

  getKeywordsStatistics(state) {
    return state.keywordsStatistics;
  },

  getSfrList(state) {
    return state.sfrList;
  },
};
