import { auth } from './auth';
import { lang } from './lang';
import { users } from './users';
import { reports } from './reports';
import { brands } from './brands';
import { products } from './products';
import { reportSources } from './report-sources';
import { companies } from './companies';
import { app } from './app';

export default {
  app,
  auth,
  lang,
  users,
  reports,
  brands,
  products,
  reportSources,
  companies,
}
