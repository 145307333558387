import { i18n } from '@/i18n';
import { store } from '@/store';

export const TIME_MODE_GLOBAL = 'global';
export const TIME_MODE_LOCAL = 'local';
export const TIME_MODE_MOSCOW = 'moscow';

export type ITimeMode = typeof TIME_MODE_GLOBAL
  | typeof TIME_MODE_LOCAL
  | typeof TIME_MODE_MOSCOW

export const getTimeModeLabel = (mode: string, full = false) => {
  switch (mode) {
    case TIME_MODE_GLOBAL: return full ? i18n.t('common.globalTime') : 'GT';
    case TIME_MODE_LOCAL: return full ? i18n.t('common.localTime') : 'LT';
    case TIME_MODE_MOSCOW: return full ? i18n.t('common.moscowTime') : 'MT';
    default: return mode;
  }
}

export const getTimeModeZone = (mode: string) => {
  switch (mode) {
    case TIME_MODE_GLOBAL: return 'utc';
    case TIME_MODE_LOCAL: return 'local';
    case TIME_MODE_MOSCOW: return 'utc+3';
    default: return mode;
  }
}

export const getCurrentTimeZone = () => {
  const timeMode = store.getters['app/getTimeMode'];
  return getTimeModeZone(timeMode);
}

export const getTimeModeList = (full = false) => {
  return [
    { value: TIME_MODE_GLOBAL, text: getTimeModeLabel(TIME_MODE_GLOBAL, full) },
    { value: TIME_MODE_LOCAL, text: getTimeModeLabel(TIME_MODE_LOCAL, full) },
    { value: TIME_MODE_MOSCOW, text: getTimeModeLabel(TIME_MODE_MOSCOW, full) },
  ]
}
