import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { AppStoreType } from '@/types/store/app-store.type';
import { ITimeMode } from '@/constants/time-mode.constants.ts';

export const actions: ActionTree<AppStoreType, StoreType> = {
  changeTimeMode({ commit }, mode: ITimeMode) {
    commit('setTimeMode', mode);
  },
};
