import { GetterTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { CompaniesStoreType } from '@/types/store/companies-store.type';

export const getters: GetterTree<CompaniesStoreType, StoreType> = {
  getCompanies(state) {
    return state.companies;
  },

  getCompany(state) {
    return state.company;
  },
};
