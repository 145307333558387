import { MutationTree } from 'vuex';
import { ProductsStoreType } from '@/types/store/products-store.type';

export const mutations: MutationTree<ProductsStoreType> = {
  SET_PRODUCTS(state, newValue) {
    state.products = newValue;
  },

  setProductKeywords(state, newValue) {
    state.productKeywords = newValue;
  },

  setKeywords(state, newValue) {
    state.keywords = newValue;
  },
};
