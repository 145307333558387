import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { CompaniesStoreType } from '@/types/store/companies-store.type';
import {
  CompanyCreateRequest,
  CompanyListRequest,
  CompanyUpdateRequest
} from '@/types/request/company-request.types';
import { CompanyType } from '@/types/api/companies/company.type';
import { companyCreate, companyGet, companyList, companyUpdate } from '@/api/company.api';

export type CompanyCard<T> = {
  id: number,
  params: T
}

export const actions: ActionTree<CompaniesStoreType, StoreType> = {
  getCompanies({ commit }, params: CompanyListRequest = {}): Promise<CompanyType[]> {
    return companyList(params).then(result => {
      if (result) {
        commit('setCompanies', result);
      }
      return result;
    });
  },

  getCompany({ commit }, id: number): Promise<CompanyType> {
    return companyGet(id).then(result => {
      if (result) {
        commit('setCompany', result);
      }
      return result;
    });
  },

  companyCreate({ commit }, params: CompanyCreateRequest): Promise<number> {
    return companyCreate(params).then(result => result);
  },

  companyUpdate({ commit }, data: CompanyCard<CompanyUpdateRequest>): Promise<void> {
    return companyUpdate(data.id, data.params).then(result => result);
  },
};
