import { GetterTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ProductsStoreType } from '@/types/store/products-store.type';

export const getters: GetterTree<ProductsStoreType, StoreType> = {
  getProducts(state) {
    return state.products;
  },

  getProductKeywords(state) {
    return state.productKeywords;
  },

  getKeywords(state) {
    return state.keywords;
  },
};
