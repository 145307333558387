import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { initialActions, store } from './store';
import { i18n } from '@/i18n';
import VModal from 'vue-js-modal';
import '@/helpers/validation.helper';
import '@/plugins';
import { AUTH_DATA_KEY, getSavedState } from '@/helpers/local-storage.helper';

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production';

const token = getSavedState(AUTH_DATA_KEY);
if (token) {
  store.commit('auth/SET_AUTH_DATA', token);
}

store.dispatch('auth/validate')
  .then(() => {
    return initialActions().then(() => start());
  })
    .catch(() => start());

function start() {
  VModal.rootInstance = new Vue({
    router,
    store,
    i18n,
    render: h => h(App as any),
  }).$mount('#app');
}
