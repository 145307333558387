import Vue from 'vue';
import {
  ButtonGroupPlugin,
  ButtonPlugin,
  DropdownPlugin,
  FormPlugin,
  FormSelectPlugin,
  TablePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  InputGroupPlugin,
  SpinnerPlugin,
  NavPlugin,
  ToastPlugin,
  TooltipPlugin,
} from 'bootstrap-vue';

Vue.use(FormSelectPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(ButtonPlugin);
Vue.use(DropdownPlugin);
Vue.use(TablePlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(InputGroupPlugin);
Vue.use(SpinnerPlugin);
Vue.use(NavPlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
