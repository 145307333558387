export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
};

/**
 * Return full content url (Example: http://some-site/upload/image.jpg)
 * if domain is not set, return part of url - /upload/image.jpg
 * @param partUrl
 */
export const getFullUrl = (partUrl: string): string => (process.env.VUE_APP_STATIC_DOMAIN || '') + partUrl;

export const MODAL_CONFIG = {
  adaptive: true,
  height: isMobile() ? '100%' : 'auto',
  ...(isMobile() ? { width: '100%' } : {}),
};
