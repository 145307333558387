import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { i18n } from '@/i18n';
import { store } from '@/store';
import { showToast, TOAST_ERROR_CONFIG } from '@/helpers/toast.helper';
import { ReportsTacosDynamicRequest } from '@/types/request/reports-request.types';

export const getBaseUrl = process.env.VUE_APP_API_URL  || '/v1';
export const http = createAxios();

export const setDefaultAuthHeaders = (accessToken: string) => {
  http.defaults.headers.common.Authorization = accessToken ? `Bearer ${accessToken}` : '';
};

export const removeDefaultAuthHeaders = () => {
  delete axios.defaults.headers.common.Authorization;
};

export const querySerializer = (params: any) => {
  let query = '';
  const getSign = () => query.length ? '&' : '';

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      query += value.length
        ? getSign() + value.map((item, index) => `${index === 0 ? '' : '&'}${key}=${item}`).join('')
        : '';
    } else {
      query += `${getSign()}${key}=${value}`;
    }
  })

  return query;
}

export function createAxios(options?: AxiosRequestConfig): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: getBaseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    ...options
  });
  axiosInstance.interceptors.response.use(response => response, errorResponseHandler);

  return axiosInstance;
}

function errorResponseHandler(error: AxiosError) {
  const errorMessage = error.response && error.response.data
      ? (error.response.data.errorMessage || i18n.t('common.serverError')) : i18n.t('common.serverError');

  if (error.response) {
    error.response.data = {
      isSuccess: false,
      errorMessage
    };
  }

  if (error.response && error.response.status !== 401) {
    showToast(errorMessage, TOAST_ERROR_CONFIG);
  } else {
    if (store.getters['auth/loggedIn']) {
      store.commit('auth/SET_AUTH_DATA', null);
      location.reload();
    }
  }

  return Promise.reject(error);
}
