import { Component, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { Action, Getter } from 'vuex-class';
import { getTimeModeLabel, getTimeModeList, getTimeModeZone, ITimeMode } from '@/constants/time-mode.constants';

const TIME_FORMAT = 'HH:mm';

@Component({
  name: 'LocalTimeSwitcher'
})
export default class LocalTimeSwitcher extends Vue {

  @Action('changeTimeMode', { namespace: 'app' })
  changeTimeMode!: (mode: ITimeMode) => Promise<void>;

  @Getter('getTimeMode', { namespace: 'app' })
  timeMode!: ITimeMode;

  changeLocalTime() {
    const modeList = getTimeModeList();
    const index = modeList.findIndex(item => item.value === this.timeMode);
    const nextIndex = index + 1 >= modeList.length ? 0 : index + 1;
    const newTimeMode = modeList[nextIndex].value as ITimeMode;
    this.changeTimeMode(newTimeMode);
  }

  get zoneTime() {
    return DateTime.local()
      .setZone(getTimeModeZone(this.timeMode))
      .toFormat(TIME_FORMAT);
  }

  get timeModeLabel() {
    return getTimeModeLabel(this.timeMode);
  }

  get timeModeLabelFull() {
    return getTimeModeLabel(this.timeMode, true);
  }
}
