import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ProductsStoreType } from '@/types/store/products-store.type';
import {
  keywords,
  productsAddKeyword, productsAddKeywords, productsDeleteImage,
  productsDeleteKeyword, productsDeleteKeywords,
  productsGet, productsKeywords,
  productsUpdate, productsUpdateKeywords,
  productsUploadImage
} from '@/api/products.api';
import {
  KeywordsRequest, KeywordsUpdateRequest,
  ProductKeywordsRequest,
  ProductsAddKeywordsRequest,
  ProductsGetRequest,
  ProductsUpdateRequest
} from '@/types/request/products-request.types';
import { KeywordType, ProductType } from '@/types/api/products/product.type';
import { ListResponseType } from '@/types/response/list-response.type';

export type ProductCard<T> = {
  id: number,
  params: T
}

export const actions: ActionTree<ProductsStoreType, StoreType> = {
  getProducts({ commit }, params: ProductsGetRequest = {}): Promise<ProductType[]> {
    return productsGet(params).then(result => {
      if (result) {
        commit('SET_PRODUCTS', result);
      }

      return result;
    });
  },

  productUpdate({ commit }, data: ProductCard<ProductsUpdateRequest>): Promise<void> {
    return productsUpdate(data.id, data.params).then(result => result);
  },

  getProductKeywords({ commit }, data: ProductCard<ProductKeywordsRequest>): Promise<KeywordType[]> {
    return productsKeywords(data.id, data.params).then(result => {
      if (result) {
        commit('setProductKeywords', result);
      }

      return result;
    });
  },

  productAddKeyword(_, data: ProductCard<ProductsAddKeywordsRequest>): Promise<{ id: number }> {
    return productsAddKeyword(data.id, data.params).then(result => result);
  },

  productDeleteKeyword(_, data: ProductCard<number>): Promise<void> {
    return productsDeleteKeyword(data.id, data.params).then(result => result);
  },

  productDeleteKeywords(_, data: ProductCard<number[]>): Promise<void> {
    return productsDeleteKeywords(data.id, data.params).then(result => result);
  },

  productUploadImage(_, data: ProductCard<File>): Promise<void> {
    return productsUploadImage(data.id, data.params).then(response => response);
  },

  productDeleteImage(_, id: number): Promise<void> {
    return productsDeleteImage(id).then(response => response);
  },

  productAddKeywords(_, data: ProductCard<string[]>): Promise<void> {
    return productsAddKeywords(data.id, data.params).then(result => result);
  },

  productUpdateKeywords(_, data: ProductCard<KeywordsUpdateRequest[]>): Promise<void> {
    return productsUpdateKeywords(data.id, data.params).then(result => result);
  },

  getKeywords({ commit }, params: KeywordsRequest): Promise<ListResponseType<KeywordType>> {
    return keywords(params).then(result => {
      if (result) {
        commit('setKeywords', result);
      }

      return result;
    });
  },
};
