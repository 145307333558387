import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { BrandsStoreType } from '@/types/store/brands-store.type';
import { BrandType } from '@/types/api/brands/brand.type';
import { brandsList } from '@/api/brands.api';

export const actions: ActionTree<BrandsStoreType, StoreType> = {
  getBrands({ commit }): Promise<BrandType[]> {
    return brandsList()
      .then(result => {
        if (result) {
          commit('SET_BRANDS', result);
        }

        return result;
      });
  },
};
