import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { enUS } from '@/i18n';

@Component
export default class LangWidget extends Vue {
  selected = '';
  options = [
    { value: enUS, text: 'EN' },
  ];

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Action('changeLang', { namespace: 'lang' })
  changeLang!: (lang: string) => Promise<void>;

  created() {
    this.selected = this.currentLang;
  }

  onLangChange() {
    this.changeLang(this.selected);
  }
}
