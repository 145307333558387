import { ReportsStoreType } from '@/types/store/reports-store.type';

export const state: ReportsStoreType = {
  productBrandReport: null,
  productDetails: null,
  tacosAcosReport: [],
  tacosReport: [],
  productWeeklyReport: null,
  tacosDynamicReport: [],
  rankingTopKwsReport: [],
  rankingOrganicSponsoredReport: [],
  searchVolumeReport: [],
  productViewReport: null,
  keywordsStatistics: null,
  sfrList: null,
};
