import Vue from 'vue';
import { i18n } from '@/i18n';
import { BvToastOptions } from 'bootstrap-vue/src/components/toast';

export const TOAST_CONFIG = {
  autoHideDelay: 5000,
  solid: true,
  toaster: 'b-toaster-bottom-center'
};

export const TOAST_ERROR_CONFIG = {
  ...TOAST_CONFIG,
  title: i18n.t('common.error') as string,
  variant: 'danger',
};

export const TOAST_SUCCESS_CONFIG = {
  ...TOAST_CONFIG,
  variant: 'success',
};

export const showToast = (title?: string, options: BvToastOptions = TOAST_CONFIG) => {
  const vue = new Vue();
  const message = title === undefined ? i18n.t('common.error') as string : title;

  vue.$bvToast.toast(message, options);
};
