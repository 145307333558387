import { http } from '@/api/http';
import {
  CompanyCreateRequest,
  CompanyListRequest,
  CompanyUpdateRequest
} from '@/types/request/company-request.types';
import { CompanyType } from '@/types/api/companies/company.type';

export const companyList = (params: CompanyListRequest): Promise<CompanyType[]> => {
  return http.get('/Companies', { params }).then(response => response.data);
};

export const companyGet = (id: number): Promise<CompanyType> => {
  return http.get(`/Companies/${id}`).then(response => response.data);
};

export const companyCreate = (params: CompanyCreateRequest): Promise<number> => {
  return http.post('/Companies', params).then(response => response.data);
};

export const companyUpdate = (id: number, params: CompanyUpdateRequest): Promise<void> => {
  return http.patch(`/Companies/${id}`, params).then(response => response.data);
};
