import { Component, Vue } from 'vue-property-decorator';
import { APP_KEY_DARK_MODE, getSavedState, saveState } from '@/helpers/local-storage.helper';

@Component({
  name: 'DarkModeSwitcher'
})
export default class DarkModeSwitcher extends Vue {
  darkMode = getSavedState(APP_KEY_DARK_MODE);

  created() {
    this.onChangeDarkMode(getSavedState(APP_KEY_DARK_MODE));
  }

  changeDarkMode() {
    this.darkMode = !this.darkMode;
    this.onChangeDarkMode(this.darkMode);
  }

  onChangeDarkMode(value) {
    if (value) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    saveState(APP_KEY_DARK_MODE, value);
  }

  get darkModeName() {
    return this.darkMode ? 'DM' : 'LM';
  }
}
