import { http } from '@/api/http';
import {
  KeywordsRequest, KeywordsUpdateRequest,
  ProductKeywordsRequest,
  ProductsAddKeywordsRequest,
  ProductsGetRequest,
  ProductsUpdateRequest
} from '@/types/request/products-request.types';
import { KeywordType, ProductType } from '@/types/api/products/product.type';
import { ListResponseType } from '@/types/response/list-response.type';

export const productsGet = (params: ProductsGetRequest): Promise<ProductType[]> => {
  return http.get('/Products', { params }).then(response => response.data);
};

export const productsUpdate = (id: number, params: ProductsUpdateRequest): Promise<void> => {
  return http.patch(`/Products/${id}`, params).then(response => response.data);
};

export const productsKeywords = (id: number, params: ProductKeywordsRequest): Promise<KeywordType[]> => {
  return http.get(`/Keywords/${id}`, { params }).then(response => response.data);
};

export const keywords = (params: KeywordsRequest): Promise<ListResponseType<KeywordType>> => {
  return http.get('/Keywords', { params }).then(response => response.data);
};

export const productsAddKeyword = (
  productId: number,
  params: ProductsAddKeywordsRequest
): Promise<{ id: number }> => {
  return http.post(`/Products/${productId}/keywords`, params).then(response => response.data);
};

export const productsDeleteKeyword = (productId: number, id: number): Promise<void> => {
  return http.delete(`/Products/${productId}/keywords/${id}`).then(response => response.data);
};

export const productsDeleteKeywords = (productId: number, ids: number[]): Promise<void> => {
  return http.delete(`/Products/${productId}/keywords/batch`, { data: ids })
    .then(response => response.data);
};

export const productsUploadImage = (productId: number, image: File): Promise<void> => {
  const formData = new FormData();
  formData.append('image', image);
  return http.post(`/Products/${productId}/image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(response => response.data);
};

export const productsDeleteImage = (productId: number): Promise<void> => {
  return http.delete(`/Products/${productId}/image`).then(response => response.data);
};

export const productsAddKeywords = (
  productId: number,
  params: string[]
): Promise<void> => {
  return http.post(`/Products/${productId}/keywords/batch`, params).then(response => response.data);
};

export const productsUpdateKeywords = (
  productId: number,
  params: KeywordsUpdateRequest[]
): Promise<void> => {
  return http.patch(`/Products/${productId}/keywords/batch`, params).then(response => response.data);
};
