<template>
  <div id="wrapper" v-if="currentUser">
    <NavBar />
    <SideBar :is-condensed="isMenuCondensed" v-on:toggle-menu="toggleMenu" />

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <!-- Start Content-->
      <div class="container-fluid">
        <div class="content" :class="{ _table: tablePage }">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SideBar from '@/components/features/side-bar';
  import NavBar from '@/components/features/nav-bar';
  import { Getter } from 'vuex-class';

  @Component({
    components: { SideBar, NavBar },
  })
  export default class Main extends Vue {
    @Prop({
      type: Boolean,
      required: false,
      default: () => false
    })
    tablePage;

    isMenuCondensed = false;

    @Getter('getCurrentUser', { namespace: 'auth' })
    currentUser;

    created() {
      document.body.classList.remove('authentication-bg');
      document.body.classList.remove('authentication-bg-pattern');
      this.isMenuCondensed = document.body.classList.contains('sidebar-enable');
    }

    toggleMenu() {
      this.isMenuCondensed = !this.isMenuCondensed;
      document.body.classList.toggle('sidebar-enable');

      if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
        document.body.classList.toggle('enlarged');
      }
    }
  }
</script>
