import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { UsersStoreType } from '@/types/store/users-store.type';
import {
  UserChangeCompanyRequest,
  UserCreateRequest,
  UserListRequest,
  UserUpdateRequest
} from '@/types/request/auth-request.types';
import { ListResponseType } from '@/types/response/list-response.type';
import { UserType } from '@/types/api/user/user.type';
import { userBlock, userChangeCompany, userCreate, userList, userUnblock, userUpdate } from '@/api/user.api';

export type UserCard<T> = {
  id: number;
  params: T;
}

export const actions: ActionTree<UsersStoreType, StoreType> = {
  getUsers({ commit }, params: UserListRequest = {}): Promise<ListResponseType<UserType>> {
    return userList(params)
      .then(result => {
        if (result) {
          commit('SET_USERS', result);
        }

        return result;
      });
  },

  createUser({ commit }, params: UserCreateRequest): Promise<UserType> {
    return userCreate(params).then(result => result);
  },

  updateUser({ commit }, data: UserCard<UserUpdateRequest>): Promise<UserType> {
    return userUpdate(data.id, data.params).then(result => result);
  },

  blockUser({ commit }, id: number): Promise<UserType> {
    return userBlock(id).then(result => result);
  },

  unblockUser({ commit }, id: number): Promise<UserType> {
    return userUnblock(id).then(result => result);
  },

  companyChange({ commit }, params: UserChangeCompanyRequest): Promise<void> {
    return userChangeCompany(params).then(result => result);
  },
};
