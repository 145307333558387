import { MutationTree } from 'vuex';
import { ReportsStoreType } from '@/types/store/reports-store.type';

export const mutations: MutationTree<ReportsStoreType> = {
  SET_PRODUCT_BRAND_REPORT(state, newValue) {
    state.productBrandReport = newValue;
  },

  SET_PRODUCT_DETAILS(state, newValue) {
    state.productDetails = newValue;
  },

  SET_TACOS_ACOS_REPORT(state, newValue) {
    state.tacosAcosReport = newValue;
  },

  SET_TACOS_REPORT(state, newValue) {
    state.tacosReport = newValue;
  },

  SET_PRODUCT_WEEKLY_REPORT(state, newValue) {
    state.productWeeklyReport = newValue;
  },

  SET_TACOS_DYNAMIC_REPORT(state, newValue) {
    state.tacosDynamicReport = newValue;
  },

  SET_RANKING_TOP_KWS_REPORT(state, newValue) {
    state.rankingTopKwsReport = newValue;
  },

  SET_RANKING_ORGANIC_SPONSORED_REPORT(state, newValue) {
    state.rankingOrganicSponsoredReport = newValue;
  },

  SET_SEARCH_VOLUME_REPORT(state, newValue) {
    state.searchVolumeReport = newValue;
  },

  SET_PRODUCT_VIEW_REPORT(state, newValue) {
    state.productViewReport = newValue;
  },

  setKeywordsStatistics(state, newValue) {
    state.keywordsStatistics = newValue;
  },

  setSfrList(state, newValue) {
    state.sfrList = newValue;
  },
};
