import { ActionTree } from 'vuex';
import { AuthStoreType } from '@/types/store/auth-store.type';
import { StoreType } from '@/types/store/store.type';
import { UserLoginRequest } from '@/types/request/auth-request.types';
import { userLogin, userLogout, userMe } from '@/api/user.api';
import { initialActions } from '@/store';
import { UserType } from '@/types/api/user/user.type';
import { AuthResponse } from '@/types/response/auth-response.types';
import { removeDefaultAuthHeaders } from '@/api/http';

export const actions: ActionTree<AuthStoreType, StoreType> = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init() {},

  signIn({ commit, dispatch, getters }, params: UserLoginRequest): Promise<AuthResponse> {
    if (getters.loggedIn) return new Promise(resolve => resolve());

    return userLogin(params)
      .then(response => {
        commit('SET_AUTH_DATA', response);
        dispatch('validate');
        initialActions();

        return response;
      });
  },

  // Logs out the current user.
  logOut({ commit }) {
    removeDefaultAuthHeaders();
    commit('SET_AUTH_DATA', null);
    // return userLogout();
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  validate({ commit }): Promise<UserType | null> {
    return userMe()
      .then(response => {
        commit('SET_CURRENT_USER', response);
        return response;
      })
      .catch(error => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          commit('SET_CURRENT_USER', null);
        }
        throw error;
      });
  },
};
