import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter, { Route } from 'vue-router';
import { routes } from '@/router/routes';
import NProgress from 'nprogress';
import { store } from '@/store';
import { loadLanguageAsync } from '@/i18n';
import { UserType } from '@/types/api/user/user.type';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo: Route, routeFrom: Route, next) => {
  // Load language
  const lang = store.getters['lang/getCurrentLang'];
  loadLanguageAsync(lang).then(() => next());

  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.start();
  }

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next();

  // If auth is required and the user is logged in...
  const currentUser: UserType = store.getters['auth/loggedIn'];
  if (currentUser) {
    return Promise.resolve().then(() => {
      // Check user roles
      // const roles = routeTo?.meta?.roles;
      // const validRoles = roles ? roles.includes(currentUser.role) : true;
      const validRoles = true;

      validRoles ? next() : redirectToLogin();
    });
  }

  // If auth is required and the user is logged in...
  if (store.getters['auth/loggedIn']) {
    return Promise.resolve().then(() => {
      next();
    });
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin();

  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }
});

router.beforeResolve(async (routeTo: Route, routeFrom: Route, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args: any) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done();
              }
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

// When each route is finished evaluating...
router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
