import { http } from '@/api/http';
import { ReportSourceType } from '@/types/api/report-sources/report-source.type';
import { ReportSourcesCreateRequest } from '@/types/request/report-sources-request.types';

export const reportSourcesGet = (): Promise<ReportSourceType[]> => {
  return http.get('/ReportSources').then(response => response.data);
};

export const reportSourcesCreate = (params: ReportSourcesCreateRequest): Promise<void> => {
  const formData = new FormData();
  if (params.source) {
    formData.append('source', params.source);
  }

  Object.entries(params).map(([key, value]) => {
    if (value && key !== 'source') {
      formData.set(key, value);
    }
  });

  return http.post('/ReportSources', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(response => response.data);
};

export const reportSourcesDelete = (id: number): Promise<void> => {
  return http.delete('/ReportSources').then(response => response.data);
};
