import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ReportSourcesStoreType } from '@/types/store/report-sources-store.type';
import { reportSourcesCreate, reportSourcesGet } from '@/api/report-sources.api';
import { ReportSourceType } from '@/types/api/report-sources/report-source.type';
import { ReportSourcesCreateRequest } from '@/types/request/report-sources-request.types';

export const actions: ActionTree<ReportSourcesStoreType, StoreType> = {
  getReportSources({ commit }): Promise<ReportSourceType[]> {
    return reportSourcesGet().then(result => {
      if (result) {
        commit('SET_REPORT_SOURCES', result);
      }

      return result;
    });
  },

  createReportSources({ commit }, param: ReportSourcesCreateRequest): Promise<void> {
    return reportSourcesCreate(param).then(result => result);
  },
};
