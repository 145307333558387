import { Route, RouteConfig } from 'vue-router';
import { store } from '@/store';
import { ROLE_ADMIN } from '@/helpers/permission.helper';

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('@/components/pages/dashboard/dashboard.vue')),
    meta: { authRequired: true },
    props: (/* route: Route */) => ({ user: store.state.auth.currentUser || {} }),
    redirect: 'product-weekly-report'
  },
  {
    path: '/users',
    name: 'users',
    component: () => lazyLoadView(import('@/components/pages/users/users.vue')),
    meta: {
      authRequired: true,
      roles: [ROLE_ADMIN]
    },
  },
  {
    path: '/brand-product-report',
    name: 'brand-product-report',
    component: () => lazyLoadView(import('@/components/pages/reports/product-brand-report/product-brand-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/revenue-ppc-report',
    name: 'revenue-ppc-report',
    component: () => lazyLoadView(import('@/components/pages/reports/revenue-ppc-report/revenue-ppc-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/organic-revenue-ppc-report',
    name: 'organic-revenue-ppc-report',
    component: () => lazyLoadView(import('@/components/pages/reports/organic-revenue-ppc-report/organic-revenue-ppc-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/organic-orders-ppc-report',
    name: 'organic-orders-ppc-report',
    component: () => lazyLoadView(import('@/components/pages/reports/organic-orders-ppc-report/organic-orders-ppc-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/tacos-acos-report',
    name: 'tacos-acos-report',
    component: () => lazyLoadView(import('@/components/pages/reports/tacos-acos-report/tacos-acos-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/product-weekly-report',
    name: 'product-weekly-report',
    component: () => lazyLoadView(import('@/components/pages/reports/product-weekly-report/product-weekly-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/ppc-metrics',
    name: 'ppc-metrics',
    component: () => lazyLoadView(import('@/components/pages/reports/ppc-metrics-report/ppc-metrics-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/sales-tacos-report',
    name: 'sales-tacos-report',
    component: () => lazyLoadView(import('@/components/pages/reports/sales-tacos-report/sales-tacos-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/ppc-organic-orders-report',
    name: 'ppc-organic-orders-report',
    component: () => lazyLoadView(import('@/components/pages/reports/ppc-organic-orders-report/ppc-organic-orders-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/ppc-sales-acos-report',
    name: 'ppc-sales-acos-report',
    component: () => lazyLoadView(import('@/components/pages/reports/ppc-sales-acos-report/ppc-sales-acos-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/ppc-corrected-ppc-sales-report',
    name: 'ppc-corrected-ppc-sales-report',
    component: () => lazyLoadView(import('@/components/pages/reports/ppc-corrected-ppc-sales-report/ppc-corrected-ppc-sales-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/total-revenue-tacos-report',
    name: 'total-revenue-tacos-report',
    component: () => lazyLoadView(import('@/components/pages/reports/total-revenue-tacos-report/total-revenue-tacos-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/tacos-dynamic-report',
    name: 'tacos-dynamic-report',
    component: () => lazyLoadView(import('@/components/pages/reports/tacos-dynamic-report/tacos-dynamic-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/ranking-top-kws-report',
    name: 'ranking-top-kws-report',
    component: () => lazyLoadView(import('@/components/pages/reports/ranking-top-kws-report/ranking-top-kws-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/ranking-organic-sponsored-report',
    name: 'ranking-organic-sponsored-report',
    component: () => lazyLoadView(import('@/components/pages/reports/ranking-organic-sponsored-report/ranking-organic-sponsored-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/search-volume-report',
    name: 'search-volume-report',
    component: () => lazyLoadView(import('@/components/pages/reports/search-volume-report/search-volume-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/report-source',
    name: 'report-source',
    component: () => lazyLoadView(import('@/components/pages/report-source/report-source.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/products',
    name: 'products',
    component: () => lazyLoadView(import('@/components/pages/products/products.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => lazyLoadView(import('@/components/pages/companies/companies.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/keyword-statistics-report',
    name: 'keyword-statistics-report',
    component: () => lazyLoadView(import('@/components/pages/reports/keyword-statistics-report/keyword-statistics-report.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('@/components/pages/auth/login/login.vue')),
    meta: {
      beforeResolve(routeTo: Route, routeFrom: Route, next: any) {
        checkLoggedIn(routeTo, routeFrom, next);
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo: Route, routeFrom: Route, next: any) {
        store.dispatch('auth/logOut');
        next({ name: 'login' });
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('@/components/features/404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
];

function checkLoggedIn(routeTo: Route, routeFrom: Route, next: any) {
  // If the user is already logged in
  if (store.getters['auth/loggedIn']) {
    // Redirect to the home page instead
    next({ name: 'home' });
  } else {
    // Continue to the login page
    next();
  }
}

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView: Promise<any>) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@/components/features/loading.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@/components/features/timeout.vue').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h: any, { data, children }: any) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
