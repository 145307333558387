import { MutationTree } from 'vuex';
import { AppStoreType } from '@/types/store/app-store.type';
import { APP_KEY_TIME_MODE, saveState } from '@/helpers/local-storage.helper';

export const mutations: MutationTree<AppStoreType> = {
  setTimeMode(state, newValue) {
    state.timeMode = newValue;
    saveState(APP_KEY_TIME_MODE, newValue);
  },
};
