import { DateTime, DurationObject, DurationUnit, Interval } from 'luxon';
import { getCurrentTimeZone } from '@/constants/time-mode.constants';
import { DateObjectUnits } from 'luxon/src/datetime';

export interface IShowingDate {
  full?: boolean,
  inputFormat?: 'seconds' | 'ms' | 'iso' | string,
  format?: string,
}

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'dd.MM.yyyy';
export const MD_FORMAT = 'MM-dd';
export const MONTH_FORMAT = 'LLLL yyyy';
export const DATEPICKER_FORMAT = 'yyyy-MM-dd_HH-mm-ss';
export const FILTER_MONTH_YEAR_FORMAT = 'MM.yyyy';
export const DATE_FILTER_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const DATE_MODE_MONTHLY = 1;
export const DATE_MODE_WEEKLY = 2;
export const DATE_MODE_DAILY = 3;
export const DATE_MODE_CUSTOM_WEEKLY = 4;

export type IDateMode = typeof DATE_MODE_MONTHLY
  | typeof DATE_MODE_WEEKLY
  | typeof DATE_MODE_DAILY
  | typeof DATE_MODE_CUSTOM_WEEKLY

export const getDayName = date => DateTime.fromFormat(date, DATE_FORMAT).day;

export const getMonthName = date => DateTime.fromFormat(date, DATE_FORMAT).monthLong;

export const getWeekName = (from, to) => {
  const monthFrom = getMonthName(from);
  const monthTo = getMonthName(to);

  return `${getDayName(from)} ${monthFrom !== monthTo ? monthFrom : ''} - ${getDayName(to)} ${monthTo}`
};

export const getDateTitle = (from, to, mode) => {
  switch (Number(mode)) {
    case DATE_MODE_MONTHLY: return getMonthName(from);
    case DATE_MODE_WEEKLY: return getWeekName(from, to);
    case DATE_MODE_CUSTOM_WEEKLY: return getWeekName(from, to);
    default: return `${getDayName(from)} ${getMonthName(from)}`;
  }
};

export const getShowingDate = (date: any, options: IShowingDate = {}) => {
  if (!date) {
    return '';
  }

  const {
    full = true,
    inputFormat = 'iso',
    format
  } = options;
  let inputDate;
  const dateFormat = format || (full ? DATE_TIME_FORMAT : DATE_FORMAT);

  switch (inputFormat) {
    case 'seconds': inputDate = DateTime.fromSeconds(date, { zone: 'utc' }); break;
    case 'ms': inputDate = DateTime.fromMillis(date, { zone: 'utc' }); break;
    case 'iso': inputDate = DateTime.fromISO(date, { zone: 'utc' }); break;
    default: inputDate = DateTime.fromFormat(date, inputFormat, { zone: 'utc' });
  }

  return inputDate
    .setZone(getCurrentTimeZone())
    .toFormat(dateFormat);
};

export const prepareDateByMode = (mode: number, date: Date, start = true) => {
  const func = start ? 'startOf' : 'endOf';

  switch (Number(mode)) {
    case DATE_MODE_MONTHLY:
      return DateTime
        .fromJSDate(date, { zone: start ? 'utc' : undefined })[func]('month')
        .toJSDate();
    case DATE_MODE_CUSTOM_WEEKLY:
      return DateTime
        .fromJSDate(date, { zone: start ? 'utc' : undefined })[func]('week')
        .toJSDate();
    default: return date;
  }
}

export const prepareDatepickerDate = (value, units: DateObjectUnits = { hour: 0, minute: 0, second: 0 }) => {
  if (!value) {
    return null;
  }

  const date = DateTime.fromJSDate(value, { zone: 'utc' })
    .set(units)
    .setZone(getCurrentTimeZone());

  return date.toUTC(-date.offset).toFormat(DATEPICKER_FORMAT);
}

export const getDateFromFormat = (date: string, from: string, to: string = DATE_TIME_FORMAT): string => {
  return DateTime.fromFormat(date, from).toFormat(to);
};

export const getDateFromISO = (date: string, toFormat: string = DATE_TIME_FORMAT): string => {
  return date ? DateTime.fromISO(date).toFormat(toFormat) : '';
};


export const isValidISODate = (date: string): boolean => {
  try {
    return DateTime.fromISO(date).isValid;
  } catch (e) {
    return false;
  }
};

export const getLastMonthsDateRange = (value: number, jsDate = false): [Date | string | null,  Date | string | null] => {
  const from = DateTime.local().minus({months: value});
  const to = DateTime.local();
  return [
    jsDate ? from.toJSDate() : from.toISODate(),
    jsDate ? to.toJSDate() : to.toISODate()
  ];
};

export const getPrevMonthsDateRange = (value: number, jsDate = false): [Date | string | null, Date | string | null] => {
  const start = DateTime.local().setZone('utc').minus({month: value}).startOf('month');
  const end = DateTime.local().minus({month: value}).endOf('month');
  return [
    jsDate ? start.toJSDate() : start.toISODate(),
    jsDate ? end.toJSDate() : end.toISODate(),
  ];
};

export const getYearDateRange = (value: number, jsDate = false): [Date | string | null, Date | string | null] => {
  const from = DateTime.local().minus({ year: value }).startOf('year');
  const to = DateTime.local().minus({ year: value }).endOf('year');
  return [
    jsDate ? from.toJSDate() : from.toISODate(),
    jsDate ? to.toJSDate() : to.toISODate()
  ];
};

export const yearsInterval = (start: DateTime, end: DateTime) => {
  const interval = Interval.fromDateTimes(start, end);
  const arrayInterval = getDateTimeArrayInterval(interval, 'year', { years: 1 });
  return Array.from(arrayInterval).map(item => item.toFormat('yyyy'));
}

export const daysInterval = (start: DateTime, end: DateTime) => {
  const interval = Interval.fromDateTimes(start, end);
  const arrayInterval = getDateTimeArrayInterval(interval, 'day', { day: 1 });
  return [
    ...Array.from(arrayInterval).map(item => item.toFormat(DATE_FORMAT)),
    end.toFormat(DATE_FORMAT)
  ]
}

// Get DateTime array of unit by interval
function* getDateTimeArrayInterval(interval: Interval, unit: DurationUnit = 'day', duration: DurationObject) {
  let cursor = interval.start.startOf(unit);

  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus(duration);
  }
}
