import {
  required,
  required_if,
  confirmed,
  length,
  email,
  min,
  max,
  min_value,
  max_value,
  regex
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { i18n } from '@/i18n';

extend('required', {
  ...required,
  params: [
    { name: 'message', default: 'validation.required' }
  ],
  message: (_, params: any) => i18n.t(params.message) as string
});

extend('required_if', {
  ...required_if,
  params: [
    { name: 'message', default: 'validation.required' }
  ],
  message: (_, params: any) => i18n.t(params.message) as string
});

extend('email', {
  ...email,
  message: () => i18n.t('validation.email') as string
});

extend('confirmed', {
  ...confirmed,
  message: () => i18n.t('validation.confirmed') as string
});

extend('length', {
  ...length,
  message: (_, params) => i18n.t('validation.length', params) as string
});

extend('min', {
  ...min,
  message: (_, params) => i18n.t('validation.min', params) as string
});

extend('max', {
  ...max,
  message: (_, params) => i18n.t('validation.max', params) as string
});

extend('min_value', {
  ...min_value,
  message: (_, params) => i18n.t('validation.min_value', params) as string
});

extend('max_value', {
  ...max_value,
  message: (_, params) => i18n.t('validation.max_value', params) as string
});

// Less than
extend('lt', {
  validate: (value, args: any) => value < Number(args.max),
  params: ['max'],
  message: (value, params) => i18n.t('validation.lessThanValue', { max: params!.max }) as string
});

// More than
extend('mt', {
  validate: (value, args: any) => value > Number(args.min),
  params: ['min'],
  message: (value, params) => i18n.t('validation.moreThanValue', { min: params!.min }) as string
});

// Use for set invalid field permanently
extend('dynamicError', {
  validate: () => false,
  params: [
    { name: 'message', default: 'validation.invalid' },
    { name: 'translate', default: false },
  ],
  message: (_, params: any) => params.translate ? i18n.t(params.message) as string : params.message
});

extend('regex', {
  ...regex,
  message: () => i18n.t('validation.invalid') as string
});
