import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ReportsStoreType } from '@/types/store/reports-store.type';
import {
  KeywordsStatisticsRequest,
  ReportsProductBrandRequest, ReportsProductDetailsRequest, ReportsProductViewRequest,
  ReportsProductWeeklyRequest, ReportsRankingOrganicSponsoredRequest, ReportsRankingTopKwsRequest,
  ReportsSearchVolumeRequest,
  ReportsTacosAcosReportRequest, ReportsTacosDynamicRequest,
  ReportsTacosRequest, SfrListRequest
} from '@/types/request/reports-request.types';
import { ProductBrandType } from '@/types/api/reports/product-brand.type';
import {
  reportsKeywordsStatistics,
  reportsProductBrand, reportsProductDetails, reportsProductViewReport,
  reportsProductWeekly,
  reportsRankingOrganicSponsored,
  reportsRankingTopKws,
  reportsSearchVolume,
  reportsTacos,
  reportsTacosAcos,
  reportsTacosDynamic, sfrList,
} from '@/api/reports.api';
import { TacosAcosType } from '@/types/api/reports/tacos-acos.type';
import { TacosType } from '@/types/api/reports/tacos.type';
import { ProductWeeklyType } from '@/types/api/reports/product-weekly.type';
import { TacosDynamicType } from '@/types/api/reports/tacos-dynamic.type';
import { RankingTopKwsType } from '@/types/api/reports/ranking-top-kws.type';
import { RankingOrganicSponsoredType } from '@/types/api/reports/ranking-organic-sponsored.type';
import { SearchVolumeType } from '@/types/api/reports/search-volume.type';
import { ProductDetailsType } from '@/types/api/reports/product-details.type';
import { ProductViewType } from '@/types/api/reports/product-view.type';
import { KeywordStatisticType } from '@/types/api/reports/keyword-statistic.type';
import { ListResponseType } from '@/types/response/list-response.type';
import { SfrType } from '@/types/api/reports/sfr.type';

export const actions: ActionTree<ReportsStoreType, StoreType> = {
  getProductBrandReport(
    { commit },
    params: ReportsProductBrandRequest = {}
  ): Promise<ProductBrandType> {
    return reportsProductBrand(params)
      .then(result => {
        if (result) {
          commit('SET_PRODUCT_BRAND_REPORT', result);
        }

        return result;
      });
  },

  getProductDetails(
    { commit },
    params: ReportsProductDetailsRequest = {}
  ): Promise<ProductDetailsType> {
    return reportsProductDetails(params)
      .then(result => {
        if (result) {
          commit('SET_PRODUCT_DETAILS', result);
        }

        return result;
      });
  },

  getTacosAcosReport(
    { commit },
    params: ReportsTacosAcosReportRequest = {}
  ): Promise<TacosAcosType[]> {
    return reportsTacosAcos(params)
      .then(result => {
        if (result) {
          commit('SET_TACOS_ACOS_REPORT', result);
        }

        return result;
      });
  },

  getTacosReport(
    { commit },
    params: ReportsTacosRequest = {}
  ): Promise<TacosType[]> {
    return reportsTacos(params)
      .then(result => {
        if (result) {
          commit('SET_TACOS_REPORT', result);
        }

        return result;
      });
  },

  getProductWeeklyReport(
    { commit },
    params: ReportsProductWeeklyRequest = {}
  ): Promise<ProductWeeklyType> {
    return reportsProductWeekly(params)
      .then(result => {
        if (result) {
          commit('SET_PRODUCT_WEEKLY_REPORT', result);
        }

        return result;
      });
  },

  getTacosDynamicReport(
    { commit },
    params: ReportsTacosDynamicRequest = {}
  ): Promise<TacosDynamicType[]> {
    return reportsTacosDynamic(params)
      .then(result => {
        if (result) {
          commit('SET_TACOS_DYNAMIC_REPORT', result);
        }

        return result;
      });
  },

  getRankingTopKwsReport(
    { commit },
    params: ReportsRankingTopKwsRequest = {}
  ): Promise<RankingTopKwsType[]> {
    return reportsRankingTopKws(params)
      .then(result => {
        if (result) {
          commit('SET_RANKING_TOP_KWS_REPORT', result);
        }

        return result;
      });
  },

  getRankingOrganicSponsoredReport(
    { commit },
    params: ReportsRankingOrganicSponsoredRequest = {}
  ): Promise<RankingOrganicSponsoredType[]> {
    return reportsRankingOrganicSponsored(params)
      .then(result => {
        if (result) {
          commit('SET_RANKING_ORGANIC_SPONSORED_REPORT', result);
        }

        return result;
      });
  },

  getSearchVolumeReport(
    { commit },
    params: ReportsSearchVolumeRequest = {}
  ): Promise<SearchVolumeType[]> {
    return reportsSearchVolume(params)
      .then(result => {
        if (result) {
          commit('SET_SEARCH_VOLUME_REPORT', result);
        }

        return result;
      });
  },

  getProductViewReport(
    { commit },
    params: ReportsProductViewRequest = {}
  ): Promise<ProductViewType> {
    return reportsProductViewReport(params)
      .then(result => {
        if (result) {
          commit('SET_PRODUCT_VIEW_REPORT', result);
        }

        return result;
      });
  },

  getKeywordsStatistics(
    { commit },
    params: KeywordsStatisticsRequest
  ): Promise<ListResponseType<KeywordStatisticType>> {
    return reportsKeywordsStatistics(params)
      .then(result => {
        if (result) {
          commit('setKeywordsStatistics', result);
        }

        return result;
      });
  },

  getSfrList({ commit }, params: SfrListRequest): Promise<ListResponseType<SfrType>> {
    return sfrList(params)
      .then(result => {
        if (result) {
          commit('setSfrList', result);
        }

        return result;
      });
  },
};
