import {
  UserChangeCompanyRequest,
  UserCreateRequest,
  UserListRequest,
  UserLoginRequest,
  UserUpdateRequest
} from '@/types/request/auth-request.types';
import { http } from '@/api/http';
import { UserType } from '@/types/api/user/user.type';
import { ListResponseType } from '@/types/response/list-response.type';
import { AuthResponse } from '@/types/response/auth-response.types';

export const userLogin = (params: UserLoginRequest): Promise<AuthResponse> => {
  return http.post('/Authentication/Login', params).then(response => response.data);
};

export const userLogout = (): Promise<void> => {
  return http.post('/Authentication/logout').then(response => response.data);
};

export const userMe = (): Promise<UserType> => {
  return http.get('/Account/Me').then(response => response.data);
};

export const userList = (params: UserListRequest): Promise<ListResponseType<UserType>> => {
  return http.get('/user', { params }).then(response => response.data);
};

export const userCreate = (params: UserCreateRequest): Promise<UserType> => {
  return http.post('/user', params).then(response => response.data);
};

export const userUpdate = (id: number, params: UserUpdateRequest): Promise<UserType> => {
  return http.patch(`/user/${id}`, params).then(response => response.data);
};

export const userBlock = (id: number): Promise<UserType> => {
  return http.post(`/user/${id}/block`).then(response => response.data);
};

export const userUnblock = (id: number): Promise<UserType> => {
  return http.post(`/user/${id}/unblock`).then(response => response.data);
};

export const userChangeCompany = (params: UserChangeCompanyRequest): Promise<void> => {
  return http.post('/Users/ChangeCompany', params).then(response => response.data);
};
