import numeral from 'numeral';

export interface INumberFormatter {
  thousandsChar?: string,
  fractionPartCount?: number,
}

// Generating a 32 random chars long string
export const generateRandomString = (): string => {
  return Array(32).fill(null).map(() => (Math.round(Math.random() * 16)).toString(16)).join('');
};

export const generatePassword = (length: number): string => {
  let generated = '';
  const symbols = '!+*-@#%^&.';
  const randUpperCase = () => String.fromCharCode(Math.floor(Math.random() * 26) + 65);
  const randLowerCase = () => String.fromCharCode(Math.floor(Math.random() * 26) + 97);
  const randNumber = () => String.fromCharCode(Math.floor(Math.random() * 10) + 48);
  const randSymbol = () => symbols[Math.floor(Math.random() * symbols.length)];
  const randFuncList = [randUpperCase, randLowerCase, randNumber, randSymbol];

  for (let i = 0; i < length; i++) {
    const funcIndex = Math.floor(Math.random() * 4);
    generated += randFuncList[funcIndex]();
  }

  return generated;
};

export const numberFormatter = (value: number, options: INumberFormatter = {}): string => {
  const {
    thousandsChar = ' ',
    fractionPartCount = 2,
  } = options;
  let fractionPart = '';
  for (let i = fractionPartCount; i > 0; i--) {
    fractionPart += i === fractionPartCount ? '.00' : '0';
  }

  return value
    ? numeral(value).format(`0,0${fractionPart}`)
      .replace(/,/ig, thousandsChar)
      .replace(/\./ig, '.')
    : '0';
};

export const toArrayBuffer = (value: string): ArrayBuffer => {
  const buf = new ArrayBuffer(value.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== value.length; ++i) {
    view[i] = value.charCodeAt(i) & 0xFF;
  }

  return buf;
};

export const getCurrencySignByCode = (code: string): string => {
  switch (code) {
    case 'EUR': return '€';
    case 'USD': return '$';
    default: return '';
  }
};

export const capitalizeFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);
