import {
  KeywordsStatisticsRequest,
  ReportsProductBrandRequest, ReportsProductDetailsRequest, ReportsProductViewRequest,
  ReportsProductWeeklyRequest, ReportsRankingOrganicSponsoredRequest, ReportsRankingTopKwsRequest,
  ReportsSearchVolumeRequest,
  ReportsTacosAcosReportRequest, ReportsTacosDynamicRequest,
  ReportsTacosRequest, SfrListRequest,
} from '@/types/request/reports-request.types';
import { ProductBrandType } from '@/types/api/reports/product-brand.type';
import { TacosAcosType } from '@/types/api/reports/tacos-acos.type';
import { TacosType } from '@/types/api/reports/tacos.type';
import { ProductWeeklyType } from '@/types/api/reports/product-weekly.type';
import { TacosDynamicType } from '@/types/api/reports/tacos-dynamic.type';
import { RankingTopKwsType } from '@/types/api/reports/ranking-top-kws.type';
import { RankingOrganicSponsoredType } from '@/types/api/reports/ranking-organic-sponsored.type';
import { SearchVolumeType } from '@/types/api/reports/search-volume.type';
import { http, querySerializer } from '@/api/http';
import { ProductDetailsType } from '@/types/api/reports/product-details.type';
import { ProductViewType } from '@/types/api/reports/product-view.type';
import { KeywordStatisticType } from '@/types/api/reports/keyword-statistic.type';
import { ListResponseType } from '@/types/response/list-response.type';
import { SfrType } from '@/types/api/reports/sfr.type';

export const reportsProductBrand = (
  params: ReportsProductBrandRequest
): Promise<ProductBrandType> => {
  return http.get('/Reports/brandAndProductView', { params }).then(response => response.data);
};

export const reportsProductDetails = (
  params: ReportsProductDetailsRequest
): Promise<ProductDetailsType> => {
  return http.get('/Reports/productDetails', { params }).then(response => response.data);
};

export const reportsProductViewReport = (
  params: ReportsProductViewRequest
): Promise<ProductViewType> => {
  return http.get('/Reports/ppcMetrics', { params }).then(response => response.data);
};

export const reportsTacosAcos = (
  params: ReportsTacosAcosReportRequest
): Promise<TacosAcosType[]> => {
  // return http.get('/user', { params }).then(response => response.data);
  return new Promise(resolve => resolve([
    {
      id: 1, brand: 'TACOS', data: [
        { date: '2021-01', count: 35 },
        { date: '2021-02', count: 35 },
        { date: '2021-03', count: 35 },
        { date: '2021-04', count: 35 },
        { date: '2021-05', count: 35 },
        { date: '2021-06', count: 35 },
        { date: '2021-07', count: 35 },
        { date: '2021-08', count: 35 },
        { date: '2021-09', count: 35 },
        { date: '2021-10', count: 35 },
        { date: '2021-11', count: 35 },
        { date: '2021-12', count: 35 },
      ]
    },
    {
      id: 2, brand: 'ACOS', data: [
        { date: '2021-01', count: 15 },
        { date: '2021-02', count: 15 },
        { date: '2021-03', count: 15 },
        { date: '2021-04', count: 15 },
        { date: '2021-05', count: 15 },
        { date: '2021-06', count: 15 },
        { date: '2021-07', count: 15 },
        { date: '2021-08', count: 15 },
        { date: '2021-09', count: 15 },
        { date: '2021-10', count: 15 },
        { date: '2021-11', count: 15 },
        { date: '2021-12', count: 15 },
      ]
    },
  ]));
};

export const reportsTacos = (
  params: ReportsTacosRequest
): Promise<TacosType[]> => {
  // return http.get('/user', { params }).then(response => response.data);
  return new Promise(resolve => resolve([
    {
      id: 1, date: '2021-01', data: [
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
      ]
    },
    {
      id: 2, date: '2021-02', data: [
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
      ]
    },
    {
      id: 3, date: '2021-03', data: [
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
      ]
    },
    {
      id: 4, date: '2021-04', data: [
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 61 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 60 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 60 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 60 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 60 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 60 },
      ]
    },
    {
      id: 5, date: '2021-05', data: [
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
      ]
    },
    {
      id: 6, date: '2021-06', data: [
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
      ]
    },
    {
      id: 7, date: '2021-07', data: [
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
      ]
    },
    {
      id: 8, date: '2021-08', data: [
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 70 },
        { product: 'Product name', count: 21 },
      ]
    },
    {
      id: 9, date: '2021-09', data: [
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 10 },
        { product: 'Product name', count: 30 },
        { product: 'Product name', count: 10 },
      ]
    },
  ]));
};

export const reportsProductWeekly = (
  params: ReportsProductWeeklyRequest
): Promise<ProductWeeklyType> => {
  return http.get('/Reports/productPerformance', { params }).then(response => response.data);
};

export const reportsTacosDynamic = (
  params: ReportsTacosDynamicRequest
): Promise<TacosDynamicType[]> => {
  return http.get('/Reports/tacosDynamicReport', {
    params,
    paramsSerializer: querySerializer,
  })
    .then(response => response.data);
};

export const reportsKeywordsStatistics = (
  params: KeywordsStatisticsRequest
): Promise<ListResponseType<KeywordStatisticType>> => {
  return http.get('/Reports/productKeywordsStatisticsView', { params })
    .then(response => response.data);
};

export const reportsRankingTopKws = (
  params: ReportsRankingTopKwsRequest
): Promise<RankingTopKwsType[]> => {
  // return http.get('/user', { params }).then(response => response.data);
  return new Promise(resolve => resolve([
    {
      id: 1, brand: '№1 Organic', data: [
        { date: '01', count: 306 },
        { date: '02', count: 306 },
        { date: '03', count: 306 },
        { date: '04', count: 306 },
        { date: '05', count: 306 },
        { date: '06', count: 306 },
        { date: '07', count: 306 },
        { date: '08', count: 190 },
        { date: '09', count: 190 },
        { date: '10', count: 190 },
        { date: '11', count: 190 },
        { date: '12', count: 306 },
      ]
    },
    {
      id: 2, brand: '№1 Sponsored', data: [
        { date: '01', count: 67 },
        { date: '02', count: 96 },
        { date: '03', count: 96 },
        { date: '04', count: 96 },
        { date: '05', count: 96 },
        { date: '06', count: 96 },
        { date: '07', count: 52 },
        { date: '08', count: 96 },
        { date: '09', count: 96 },
        { date: '10', count: 46 },
        { date: '11', count: 96 },
        { date: '12', count: 96 },
      ]
    },
  ]));
};

export const reportsRankingOrganicSponsored = (
  params: ReportsRankingOrganicSponsoredRequest
): Promise<RankingOrganicSponsoredType[]> => {
  // return http.get('/user', { params }).then(response => response.data);
  return new Promise(resolve => resolve([
    {
      id: 1, brand: 'Organic iron tablets', data: [
        { date: '2021-01-01', count: 306 },
        { date: '2021-01-02', count: 306 },
        { date: '2021-01-03', count: 306 },
        { date: '2021-01-04', count: 306 },
        { date: '2021-01-05', count: 306 },
        { date: '2021-01-06', count: 306 },
        { date: '2021-01-07', count: 306 },
        { date: '2021-01-08', count: 190 },
        { date: '2021-01-09', count: 190 },
        { date: '2021-01-10', count: 190 },
        { date: '2021-01-11', count: 190 },
        { date: '2021-01-12', count: 306 },
        { date: '2021-01-13', count: 306 },
        { date: '2021-01-14', count: 190 },
        { date: '2021-01-15', count: 306 },
        { date: '2021-01-16', count: 190 },
        { date: '2021-01-17', count: 190 },
        { date: '2021-01-18', count: 190 },
        { date: '2021-01-19', count: 306 },
        { date: '2021-01-20', count: 190 },
        { date: '2021-01-21', count: 180 },
        { date: '2021-01-22', count: 180 },
        { date: '2021-01-23', count: 190 },
        { date: '2021-01-24', count: 190 },
        { date: '2021-01-25', count: 306 },
        { date: '2021-01-26', count: 190 },
        { date: '2021-01-27', count: 306 },
        { date: '2021-01-28', count: 180 },
        { date: '2021-01-29', count: 190 },
        { date: '2021-01-30', count: 306 },
        { date: '2021-01-31', count: 190 },
      ]
    },
    {
      id: 2, brand: 'Sponsored iron tablets', data: [
        { date: '2021-01-01', count: 67 },
        { date: '2021-01-02', count: 96 },
        { date: '2021-01-03', count: 96 },
        { date: '2021-01-04', count: 96 },
        { date: '2021-01-05', count: 96 },
        { date: '2021-01-06', count: 96 },
        { date: '2021-01-07', count: 52 },
        { date: '2021-01-08', count: 96 },
        { date: '2021-01-09', count: 96 },
        { date: '2021-01-10', count: 46 },
        { date: '2021-01-11', count: 96 },
        { date: '2021-01-12', count: 46 },
        { date: '2021-01-13', count: 46 },
        { date: '2021-01-14', count: 52 },
        { date: '2021-01-15', count: 46 },
        { date: '2021-01-16', count: 96 },
        { date: '2021-01-17', count: 46 },
        { date: '2021-01-18', count: 46 },
        { date: '2021-01-19', count: 96 },
        { date: '2021-01-20', count: 46 },
        { date: '2021-01-21', count: 96 },
        { date: '2021-01-22', count: 96 },
        { date: '2021-01-23', count: 96 },
        { date: '2021-01-24', count: 52 },
        { date: '2021-01-25', count: 96 },
        { date: '2021-01-26', count: 46 },
        { date: '2021-01-27', count: 52 },
        { date: '2021-01-28', count: 52 },
        { date: '2021-01-29', count: 46 },
        { date: '2021-01-30', count: 46 },
        { date: '2021-01-31', count: 96 },
      ]
    },
  ]));
};

export const reportsSearchVolume = (
  params: ReportsSearchVolumeRequest
): Promise<SearchVolumeType[]> => {
  // return http.get('/user', { params }).then(response => response.data);
  return new Promise(resolve => resolve([
    {
      id: 1, brand: 'v1', data: [
        { date: '2021-01-01', count: 306 },
        { date: '2021-01-02', count: 306 },
        { date: '2021-01-03', count: 306 },
        { date: '2021-01-04', count: 306 },
        { date: '2021-01-05', count: 306 },
        { date: '2021-01-06', count: 306 },
        { date: '2021-01-07', count: 306 },
        { date: '2021-01-08', count: 190 },
        { date: '2021-01-09', count: 190 },
        { date: '2021-01-10', count: 190 },
        { date: '2021-01-11', count: 190 },
        { date: '2021-01-12', count: 306 },
        { date: '2021-01-13', count: 306 },
        { date: '2021-01-14', count: 190 },
        { date: '2021-01-15', count: 306 },
        { date: '2021-01-16', count: 190 },
        { date: '2021-01-17', count: 190 },
        { date: '2021-01-18', count: 190 },
        { date: '2021-01-19', count: 306 },
        { date: '2021-01-20', count: 190 },
        { date: '2021-01-21', count: 180 },
        { date: '2021-01-22', count: 180 },
        { date: '2021-01-23', count: 190 },
        { date: '2021-01-24', count: 190 },
        { date: '2021-01-25', count: 306 },
        { date: '2021-01-26', count: 190 },
        { date: '2021-01-27', count: 306 },
        { date: '2021-01-28', count: 180 },
        { date: '2021-01-29', count: 190 },
        { date: '2021-01-30', count: 306 },
        { date: '2021-01-31', count: 190 },
      ]
    },
    {
      id: 2, brand: 'v2', data: [
        { date: '2021-01-01', count: 67 },
        { date: '2021-01-02', count: 96 },
        { date: '2021-01-03', count: 96 },
        { date: '2021-01-04', count: 96 },
        { date: '2021-01-05', count: 96 },
        { date: '2021-01-06', count: 96 },
        { date: '2021-01-07', count: 52 },
        { date: '2021-01-08', count: 96 },
        { date: '2021-01-09', count: 96 },
        { date: '2021-01-10', count: 46 },
        { date: '2021-01-11', count: 96 },
        { date: '2021-01-12', count: 46 },
        { date: '2021-01-13', count: 46 },
        { date: '2021-01-14', count: 52 },
        { date: '2021-01-15', count: 46 },
        { date: '2021-01-16', count: 96 },
        { date: '2021-01-17', count: 46 },
        { date: '2021-01-18', count: 46 },
        { date: '2021-01-19', count: 96 },
        { date: '2021-01-20', count: 46 },
        { date: '2021-01-21', count: 96 },
        { date: '2021-01-22', count: 96 },
        { date: '2021-01-23', count: 96 },
        { date: '2021-01-24', count: 52 },
        { date: '2021-01-25', count: 96 },
        { date: '2021-01-26', count: 46 },
        { date: '2021-01-27', count: 52 },
        { date: '2021-01-28', count: 52 },
        { date: '2021-01-29', count: 46 },
        { date: '2021-01-30', count: 46 },
        { date: '2021-01-31', count: 96 },
      ]
    },
  ]));
};

export const sfrList = (params: SfrListRequest): Promise<ListResponseType<SfrType>> => {
  return http.get('/Sfr', { params }).then(response => response.data);
};
