import { Component, Vue, Prop } from 'vue-property-decorator';
import MetisMenu from 'metismenujs/dist/metismenujs';
import LangWidget from '@/components/ui/lang-widget/langWidget.vue';
import { Getter } from 'vuex-class';
import { UserType } from '@/types/api/user/user.type';
import { ROLE_ADMIN } from '@/helpers/permission.helper';
import { isMobile } from '@/helpers/app.helper';
import DarkModeSwitcher from '@/components/features/settings-bar/dark-mode-switcher/dark-mode-switcher.vue';
import LocalTimeSwitcher from '@/components/features/settings-bar/local-time-switcher/local-time-switcher.vue';
import SideNavDivider from '@/components/features/side-nav/side-nav-divider.vue';
import { BDropdownItem, BDropdownDivider, BNavItemDropdown } from 'bootstrap-vue';
import { APP_KEY_MENU_BLOCKS, getSavedState, saveState } from '@/helpers/local-storage.helper';

@Component({
  name: 'SideNav',
  components: {
    LangWidget,
    BDropdownItem,
    BDropdownDivider,
    BNavItemDropdown,
    DarkModeSwitcher,
    LocalTimeSwitcher,
    SideNavDivider,
  }
})
export default class SideNav extends Vue {
  blocksToggle = {
    rankingSearchVolume: true,
    salesPpcSpendUnits: true,
    tacosAcosSales: true,
    settings: true,
    personal: true,
    ...getSavedState(APP_KEY_MENU_BLOCKS) || {}
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  isCondensed?: boolean;

  @Getter('getCurrentUser', { namespace: 'auth' })
  user!: UserType;

  mounted() {
    // eslint-disable-next-line no-unused-vars
    const menuRef = new MetisMenu('#side-menu');
    const activeElement = menuRef.element.querySelector('.mm-active');
    if (!isMobile() && this.isCondensed && activeElement) {
      const showElement = activeElement.querySelector('.mm-show');
      if (showElement) {
        showElement.classList.remove('mm-show');
      }
    }
  }

  isActive(route) {
    return this.$route.name === route ? 'active' : '';
  }

  get userName() {
    return this.user.email;
  }

  get isCanLookUsers() {
    return this.user.role === ROLE_ADMIN;
  }

  toggleMenu() {
    this.$emit('toggle-menu');
  }

  getPpcViewRoutes() {
    return [
      'brand-product-report',
      'product-weekly-report',
      'ppc-metrics',
      'keyword-statistics-report',
    ];
  }

  getRankingSearchVolumeRoutes() {
    return [
      'ranking-top-kws-report',
      'ranking-organic-sponsored-report',
      'search-volume-report',
    ];
  }

  getSalesSearchVolumeRoutes() {
    return [
      'organic-revenue-ppc-report',
      'organic-orders-ppc-report',
    ];
  }

  getTacosAcosSalesRoutes() {
    return [
      'tacos-acos-report',
      'sales-tacos-report',
      'ppc-sales-acos-report',
      'tacos-dynamic-report',
    ];
  }

  onToggleBlock(block: string) {
    this.blocksToggle[block] = !this.blocksToggle[block];
    saveState(APP_KEY_MENU_BLOCKS, this.blocksToggle);
  }
}
