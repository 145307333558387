export const AUTH_DATA_KEY = 'auth.data';
export const APP_KEY_LANG = 'app.key.lang';
export const APP_KEY_DARK_MODE = 'app.key.dark_mode';
export const APP_KEY_TIME_MODE = 'app.key.time_mode';
export const APP_KEY_CHART_SHOW = 'app.key.chart_show';
export const APP_KEY_MENU_BLOCKS = 'app.key.menu_blocks';
export const APP_KEY_CHART_PICKER = 'app.key.chart_picker';
export const APP_KEY_ACOS_TACOS_WIDGET_FROM = 'app.key.acos_tacos_widget_from';
export const APP_KEY_ACOS_TACOS_WIDGET_TO = 'app.key.acos_tacos_widget_to';

export const getSavedState = (key: string) => {
  const item = window.localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const saveState = (key: string, state: any) => {
  window.localStorage.setItem(key, JSON.stringify(state))
};

export const removeState = (key: string) => {
  window.localStorage.removeItem(key);
};
