<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="float-right pl-4 pr-4">
          <LangWidget />
        </div>
      </div>

      <div class="col-sm-12">
        <div class="account-pages mt-5 mb-5">
          <div class="container">
            <slot />
          </div>
        </div>
        <footer class="footer footer-alt">
<!--          2016 - 2019 © Ubold theme by <a href="" class="text-muted">Coderthemes</a>-->
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import LangWidget from '@/components/ui/lang-widget/langWidget.vue';

  @Component({
    components: { LangWidget }
  })
  export default class Auth extends Vue {
    created() {
      document.body.classList.add('authentication-bg');
      document.body.classList.add('authentication-bg-pattern');
    }
  }
</script>
