import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { Module } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { BrandsStoreType } from '@/types/store/brands-store.type';

const namespaced: boolean = true;
export const brands: Module<BrandsStoreType, StoreType> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
