<template>
  <li class="dropdown-title" v-on="$listeners">
    <div class="title">{{ title }}</div>
    <div class="chevron">
      <i :class="{ 'fe-chevron-right': !open, 'fe-chevron-down': open }" />
    </div>
  </li>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'SideNavDivider',
})
export default class SideNavDivider extends Vue {
  @Prop({ required: true })
  title

  @Prop({
    required: false,
    default: () => true
  })
  open
}
</script>
<style lang="scss" scoped>
.dropdown-title {
  font-weight: 500;
  color: #343330;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.6875rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
  opacity: 0.7;
  position: relative;
  text-align: center;
  cursor: pointer;

  .dropdown-divider {
    position: absolute;
    width: 253px;
  }

  .title {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    padding: 0 20px;
    width: 100%;
  }

  .chevron {
    position: absolute;
    right: 0;
    padding: 0 5px;
  }
}
</style>
