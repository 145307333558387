import { i18n } from '@/i18n';
import { TranslateResult } from 'vue-i18n';

export const ROLE_PARTNER = 1;
export const ROLE_ADMIN = 2;

export const getRoleLabel = (role: number): TranslateResult => {
  const labelsMap: {[key: number]: string} = {
    [ROLE_PARTNER]: 'role.partner',
    [ROLE_ADMIN]: 'role.admin',
  };

  return i18n.t(labelsMap[role]);
};

export const getRolesAsArray = () => [
  { value: ROLE_PARTNER, text: getRoleLabel(ROLE_PARTNER) },
  { value: ROLE_ADMIN, text: getRoleLabel(ROLE_ADMIN) },
];

export const getRolesAsList = () => [ROLE_PARTNER, ROLE_ADMIN];
