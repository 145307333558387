import { MutationTree } from 'vuex';
import { AuthStoreType } from '@/types/store/auth-store.type';
import { removeDefaultAuthHeaders, setDefaultAuthHeaders } from '@/api/http';
import { AuthResponse } from '@/types/response/auth-response.types';
import { AUTH_DATA_KEY, removeState, saveState } from '@/helpers/local-storage.helper';

export const mutations: MutationTree<AuthStoreType> = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
  },

  SET_AUTH_DATA(state, newValue: AuthResponse) {
    state.authData = newValue;
    if (newValue) {
      setDefaultAuthHeaders(newValue.token);
      saveState(AUTH_DATA_KEY, newValue);
    } else {
      removeDefaultAuthHeaders();
      removeState(AUTH_DATA_KEY);
    }
  },
};
