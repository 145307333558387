import { MutationTree } from 'vuex';
import { CompaniesStoreType } from '@/types/store/companies-store.type';

export const mutations: MutationTree<CompaniesStoreType> = {
  setCompanies(state, newValue) {
    state.companies = newValue;
  },

  setCompany(state, newValue) {
    state.company = newValue;
  },
};
