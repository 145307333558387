import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/i18n/messages/en-US.json';
import { http } from '@/api/http';
import { Settings } from 'luxon';

Vue.use(VueI18n);

export const enUS = 'en-US';
export const LANGUAGES = [enUS];

// export const defaultLang = LANGUAGES.includes(window.navigator.language) ? window.navigator.language : enUS;
export const defaultLang = enUS;
export const i18n = new VueI18n({
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages: {
    'en-US': en,
  },
});

const loadedLanguages = [defaultLang]; // our default language that is preloaded
export const languagesMap = {
  'en-US': 'en',
};

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  http.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html')!.setAttribute('lang', lang);
  Settings.defaultLocale = lang;

  return lang;
}

export function loadLanguageAsync(lang: string) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/messages/${ lang }.json`)
    .then(messages => {
        i18n.setLocaleMessage(lang, messages.default);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
      }
    );
}
